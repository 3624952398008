.submitted-section-box {
  //   padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  margin-bottom: 15px;
  .submitted-section-image {
    max-width: 90px;
    img {
      width: 100%;
    }
  }
  .submitted-section-description {
    p {
      text-align: center;
      font-size: 28px;
      font-weight: 500;
    }
  }
}
