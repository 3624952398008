.card-primary {
  max-width: 650px;
  padding: 30px;
  .card-box-main {
    position: relative;
    cursor: pointer;
    .card-box-holder {
      position: relative;
      background: #fff;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: 25px;
      color: #000;
      padding: 50px;
      z-index: 99;

      --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
        0 2px 4px -2px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
        0 2px 4px -2px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

      transition: transform 0.3s ease-in-out;
      -webkit-transition: transform 0.3s ease-in-out;
      &:hover {
        transform: translateY(-10px);
      }
    }
    &::after {
      content: "";
      background: linear-gradient(to top, #ff5d02, #02a4ff);
      position: absolute;
      bottom: -30px;
      right: -30px;
      width: 100%;
      height: 100%;
    }
  }
  .card {
    &-header {
      img {
        width: 100%;
        @media screen and (min-width: 769px) {
          min-width: 130px;
        }
      }
    }
  }
}
