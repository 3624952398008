.story-section {
  .description {
    .story-summary {
      margin-bottom: 45px;
      text-align: center;
    }
    .story-body {
      &-image {
        float: right;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        max-width: 40%;
        min-width: 320px;
        img {
          width: 100%;
        }
        &::after {
          content: "";
          clear: both;
        }
      }
      &-summary:not(:last-child) {
        margin-bottom: 25px;
        display: block;
      }
      .story-read {
        &-more,
        &-less {
          display: none;
          &.active {
            display: none;
          }
          color: rgb(255, 204, 0);
          text-decoration: underline;
          cursor: pointer;
          max-width: 130px;
          &:hover {
            color: #fff;
          }
        }
      }
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        &-image {
          float: unset;
          order: 4;
          margin: 0;
          margin-top: 20px;
          max-width: 100%;
        }
        &-summary:not(:last-child) {
          margin-bottom: 10px;
        }
        .story-read {
          &-more,
          &-less {
            &.active {
              display: block;
            }
          }
        }
        .story-body-summary-box {
          display: none;
          margin-bottom: 15px;
          &.active {
            display: block;
          }
        }
      }
    }
  }
}
