.mission-section {
  margin-top: 65px !important;
  .mission-summary {
    span:not(:last-child) {
      display: block;
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width: 768px) {
    .mission-summary {
      span:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
