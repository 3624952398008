.aboutUs-main {
  padding: 65px 20px 65px 20px;
  background: rgb(8, 7, 54);
  border-top: 35px solid #be060c;
  .aboutUs-item {
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    letter-spacing: 2px;
    line-height: 25px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    h1,
    h2,
    h3 {
      text-align: center;
      color: rgb(255, 204, 0);
    }
    div,
    span,
    p {
      color: #fff;
    }
    .description {
      @media screen and (max-width: 768px) {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 20px;
      }
    }
  }
}
