@mixin buttonStyle($bgColor, $color) {
  background: $bgColor !important;
  color: $color !important;
  padding: 15px;
  border: none;
  outline: none;
  border-radius: 10px;
  text-decoration: none !important;
  font-size: 14px !important;
}

.btn-primary {
  @include buttonStyle($bgColor: #1e1548eb, $color: #fff);
}

.btn-secondary {
  @include buttonStyle($bgColor: #fa4b00, $color: #fff);
}

.btn-primary,
.btn-secondary {
  font-weight: 500;
  cursor: pointer;
  margin-left: 20px;
  transition: all 0.3s;
  &:hover {
    opacity: 0.8;
  }
}
