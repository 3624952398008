.services {
  &-main {
    // margin-top: 100px;
    // margin-bottom: 40px;
    background-color: #21516c;
    color: #fff;
    // padding: 65px;
    padding: 65px 20px 70px 20px;
    &-heading {
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;

      & > h1 {
        margin-bottom: 10px;
      }
    }
  }
  &-slider {
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .swiper-pagination {
      width: 100%;
      //   bottom: 10px;
      bottom: -70px;
      &-wrapper {
        // max-width: 350px;
        margin: 0 auto;
        .swiper-pagination-bullet {
          position: relative;
          //   width: 33%;
          width: 100px;
          height: auto;
          text-align: left;
          border-radius: 0;
          opacity: 1;
          //   margin: 0 !important;
          margin-right: 20px;
          background-color: transparent;
          div {
            font-size: 12px;
            line-height: 28px;
            font-weight: bold;
            //   letter-spacing: -0.38px;
            text-transform: uppercase;
            text-align: center;
            color: rgba(255, 255, 255, 0.636);
            margin-bottom: 5px;
            &:hover {
              color: #fff;
            }
          }
          i {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 8px;
            background-color: #fa4b00;
            border-radius: 5px;
          }
          b {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 0%;
            height: 8px;
            background-color: #7c2313;
            border-radius: 5px;
          }
        }
        .swiper-pagination-bullet-active {
          background-color: transparent;
          div {
            color: #fa4b00;
            font-weight: bold;
            opacity: 1;
          }
          i {
            background-color: #e98383;
          }
          b {
            animation-name: countingBar;
            animation-duration: 3s;
            animation-timing-function: ease-in;
            animation-iteration-count: 1;
            animation-direction: alternate;
            animation-fill-mode: forwards;
          }
        }
        @media screen and (max-width: 465px) {
          width: calc(100% - 20px);
        }
      }
    }
  }
  &-lists {
    display: none;
    margin-top: 30px;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    .card-primary {
      padding: 20px;
      .card-box-main {
        .card-box-holder {
          padding: 25px;
        }
        &::after {
          bottom: -20px;
          right: -20px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    &-main {
      padding: 65px 20px 35px 20px;
    }
    &-slider {
      display: none;
    }
    &-lists {
      //   display: block;
      display: flex;
    }
  }
  @media screen and (max-width: 650px) {
    &-lists {
      .card-primary {
        .card-box-main {
          .card-box-holder {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            .card-header {
              max-width: 100px;
            }
            .card-body {
              text-align: center;
            }
          }
        }
      }
    }
  }

  @keyframes countingBar {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}
