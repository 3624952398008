.blogs-card {
  margin-bottom: 15px;

  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    height: 210px;
    img {
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: top;
      object-position: top;
      height: 100%;

      vertical-align: top;
    }
  }

  &-title {
    padding: 20px;
    text-align: left;

    h3 {
      margin-bottom: 15px;
      text-decoration: none;
      font-weight: 700;
      font-size: 25px;
    }

    .summary {
      .blogs-heading-summary {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        height: 40px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 1;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: 1px;
        line-height: 18px;
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &-image {
      height: 170px;
    }
    &-title {
      padding: 10px 15px;
      h3 {
        font-size: 20px;
      }
      .summary {
        .blogs-heading-summary {
          font-size: 13px;
        }
      }
    }
  }
}
