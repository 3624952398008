.clients-main {
  background-image: url("../../../images/clients/clients-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  h1,
  p,
  span,
  div {
    color: #233340;
  }

  padding: 0 20px 70px 20px;

  &-image {
    color: #fff;
    background: linear-gradient(to top right, #be060c, #f70612);
    box-shadow: rgba(0, 0, 0, 0.5) -5px 5px 10px 2px;
    padding: 25px;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-size: 20px;
    line-height: 35px;

    border-top: 10px solid rgba(30, 21, 72, 0.9215686275);
    border-bottom: 10px solid rgba(30, 21, 72, 0.9215686275);
    border-radius: 50px 10px 50px 10px;

    transform: translateY(-50px);

    h3 {
      color: #fff;
    }
  }
  &-box {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    .description-primary {
      font-weight: 600;
    }
    .clients-name-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 50px;
      //   margin: 0 auto;
      //   max-width: 700px;
      margin-top: 50px;

      .clients-opt {
        // flex: 1;
        max-width: 300px;
        img {
          width: 100%;
          cursor: pointer;
        }
      }
      .clents-opt-extra {
        margin-top: 30px;
        font-size: 40px;
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
      }
      @media screen and (max-width: 768px) {
        .tooltip {
          display: none;
        }
      }
    }
  }
}
