.blog-description-main {
  max-width: 768px;
  margin: 0 auto;
  padding: 65px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1,
  h3,
  span {
    font-family: "Roboto", sans-serif !important;
    letter-spacing: 0.5px;
    line-height: 25px;
  }

  span {
    font-size: 15px;
  }

  .blog {
    &-image {
      img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: top;
        object-position: top;
        height: 100%;
        vertical-align: top;
      }
    }
    &-heading {
      margin: 60px 0;
      &-primary {
        .heading-primary {
          line-height: unset;
        }
        .heading-primary-author {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;
          margin-bottom: 10px;
          span {
            font-weight: 600;
          }
          .author {
            &-img {
              height: 45px;
              width: 45px;

              img {
                width: 100%;
                height: 100%;
                -o-object-fit: contain;
                object-fit: contain;
                border-radius: 50%;
                filter: grayscale(100%);
                transition: all 0.25s ease-in-out;
                transform-origin: center;

                &:hover {
                  transform: scale(1.1);
                  filter: grayscale(0%);
                }
              }
            }
            &-info {
              display: flex;
              //   gap: 15px;
              max-width: 350px;
              flex-wrap: wrap;
              .author-name {
                margin-right: 15px;
              }
              flex-basis: calc(100% - 60px);
            }
            &-extra {
              display: flex;
              gap: 15px;
              //   span{

              //   }
              //   &:not(:last-child) {
              //     margin-right: 15px;
              //   }
            }
            &-position {
              opacity: 0.7;
            }
            &-linkedin {
              width: 25px;
              height: 25px;
              //   display: block;
              a {
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    &-body-main {
      & > div {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        .blogs-body-content {
          &-heading {
            display: block;
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 600;
          }
          &-subheading {
            font-size: 15px;
            font-weight: 600;
          }
          &-body {
            ul {
              list-style: none;
              li {
                color: #3f0f64;
                padding-left: 35px;
                position: relative;
                margin-bottom: 15px;

                &::before {
                  content: "";
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  background-color: #b266ed;
                  border-radius: 50%;
                  //   top: 5px;
                  top: 8px;
                  left: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
