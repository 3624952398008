@import url("../js/components/*.scss");
@import url("../js/components\/**\/*.scss");
@import url("../js/blogsComponent\/**\/*.scss");

* {
  background-color: transparent;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: "Inter Tight", sans-serif;
  font-weight: 400;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

#root {
  background: #f7f7f7;
  position: relative;
}

.heading-primary {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 2.2rem;
}

.description-primary {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 1px;
}

.tooltip {
  max-width: 650px;
  color: #233340 !important;
  font-weight: 600 !important;
  background-color: #fff0e0 !important;
  padding: 30px 15px !important;
  border-radius: 10px !important;
  text-align: left !important;
  opacity: 1 !important;
  & > div {
    background-color: #fff0e0 !important;
  }
  span,
  p {
    //   color: #fff;
    color: #233340;
    font-weight: 600;
  }
  @media screen and (max-width: 768px) {
    max-width: 320px;
    padding: 10px;
  }
}

.blogs-page {
  .services-button {
    display: none !important;
  }
}
