/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  h1,
  h2,
  h3,
  p,
  a {
    font-family: "Arvo", serif !important;
  }
  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
    .row {
      // margin-right: -15px;
      // margin-left: -15px;
      padding-right: 15px;
      padding-left: 15px;
      //   @media (min-width: 768px) {
      //     .col-sm-offset-1 {
      //       margin-left: 8.33333333%;
      //       .col-sm-10 {
      //         width: 83.33333333%;
      //       }
      //     }
      //   }
    }
  }
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url("../../../images/error/error404.gif");
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
  text-align: center;
  color: rgb(51, 51, 51);
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 10px;
  }
}
.contant_box_404 {
  text-align: center;
  margin-top: -50px;
  h3 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  p {
    margin: 0 0 10px;
  }
}
