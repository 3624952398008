.contactus-section {
  &-main {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100vh;
    transform: translate(50%, 80%);
    transition: 0.3s ease-in;
    z-index: 99999;

    &.active {
      transform: translateX(-50%);
      visibility: visible;

      .contactus-section-body-main {
        transform: translate(-50%, -50%);
      }
    }
  }

  &-overlay {
    background: #000;
    opacity: 0.7;
    height: 100%;
  }
  &-body-main {
    // padding: 25px;
    transition: 0.7s ease-in;
    position: absolute;
    top: 50%;
    left: 50%;
    // transform: translate(0%, -50%);
    transform: translate(0%, -25%);
  }
  &-box {
    padding: 20px 35px;
    background-image: url("../../../images/clients/clients-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    max-height: 93vh;
    overflow-y: auto;
    overflow-x: hidden;
    h1,
    label,
    input,
    textarea {
      color: #233340 !important;
      font-weight: 600;
    }
    h1 {
      font-size: 35px;
      text-align: center;
    }
    .contactus-close-button {
      position: absolute;
      top: -25px;
      right: -25px;
      cursor: pointer;

      border-radius: 50%;
      background: #fff;
      width: 35px;
      height: 35px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;
      margin-left: 20px;

      div[class^="line"] {
        width: 30px;
        height: 5px;
        background: #eb4d4d;
        border-radius: 5px;
        transition: all 0.3s;
      }
      .line-1 {
        transform: rotate(45deg) translate(7px, 7.5px);
      }
      .line-3 {
        transform: rotate(-45deg) translate(7px, -7.5px);
      }
    }
    @media screen and (max-width: 768px) {
      padding: 20px 20px;
      // max-height: 75vh;
      max-height: 87vh;

      .contactus-close-button {
        top: 0;
        right: 0;
        background: transparent;
      }
      h1 {
        font-size: 28px;
      }
    }
  }
}
