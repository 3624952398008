@mixin defaultFlex() {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

footer {
  background-color: #1b1b1a;
  padding: 30px 0;
  margin-top: auto;
  .footer-main {
    color: #fa4b00;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    // max-width: 330px;
    max-width: 340px;
    margin: 0 auto;
    text-align: center;
  }
  .footer-items,
  .rights-reserved {
    width: 100%;
  }
  .footer-items {
    @include defaultFlex();
    justify-content: center;
    gap: 15px;
    .btn-secondary {
      margin-left: 0;
    }
    a {
      color: #fa4b00;
      text-decoration: underline;
      transition: all 0.3s;
      &:hover {
        color: #fff;
      }
      cursor: pointer;
    }
    .contactus-social-media {
      margin: 0;
    }
  }
}
