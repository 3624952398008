.go-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  z-index: 999;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }

  div {
    background: #202020;
    border-radius: 50%;
    padding: 5px;
    height: 30px;
    width: 30px;
    margin-bottom: 5px;

    svg {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }
}

// .go-to-top-button:hover {
//   background-color: #0062cc;
// }
