.contactus-social-media {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //   padding: 10px;
  padding: 6px 10px;
  gap: 10px;

  max-width: 90px;
  border: 2px solid #fff;
  margin: 0 auto;
  margin-bottom: 15px;

  & > div {
    a {
      display: block;
      width: 30px;
      height: 30px;
      //   padding: 5px;
    }

    img {
      width: 100%;
      height: 100%;
      transition: 0.3s all;
      filter: grayscale(100%);
      &:hover {
        filter: grayscale(0);
        scale: 1.1;
      }
    }
  }
}
