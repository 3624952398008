.hero-main {
  //   margin-top: 70px;
  // margin-top: 40px;
  max-width: 1200px;
  margin: 0 auto;
  // margin-bottom: 40px;
  margin-bottom: 100px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // gap: 10px;
  gap: 20px;
  & > div {
    flex: 1;
  }
  //   &-left {
  //     flex: 4;
  //   }
  &-right {
    // flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      // max-width: 350px;
    }
  }
  // &-heading {
  //   font-weight: 700;
  //   margin-bottom: 20px;
  //   font-size: 2.2rem;
  // }
  &-subheading {
    margin-bottom: 5px;
  }
  // &-description {
  //   font-weight: 400;
  //   font-size: 0.875rem;
  //   line-height: 20px;
  //   letter-spacing: 1px;
  // }
  @media screen and (max-width: 860px) {
    gap: 30px;
    &-heading {
      font-size: 1.96rem;
    }
    &-subheading {
      font-size: 1.3rem;
    }
  }
  @media screen and (max-width: 768px) {
    max-width: 500px;
    margin: 40px auto;
    // &-right {
    //   display: none;
    // }
    flex-direction: column;
  }
}
