.blogs-home-main {
  padding: 50px 20px;
  background-image: url("../../../images/blogs/blogs-bg.jpg");
  h1,
  h3,
  p,
  span {
    color: #233340;
  }

  &-box {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
}
