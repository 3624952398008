@mixin defaultFlex() {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-main,
.nav-items {
  @include defaultFlex;
}
header {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  background: #fff;
  width: 100%;
  overflow: hidden;
  //   position: fixed;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
}
.nav-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  letter-spacing: 2px;
  .logo-main {
    &-img {
      // width: 200px;
      width: 170px;
    }
  }
  .nav-items {
    a {
      text-decoration: none;
      color: #000;
      margin: 0 25px;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.3s;
      &:hover {
        color: #eb4d4d;
        opacity: 0.8;
      }
    }
    &.sm-screen {
      visibility: hidden;
      position: fixed;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      right: -200px;
      top: 61px;
      background: #fa4b00;
      padding: 25px 35px;
      color: #fff;
      gap: 15px;
      transition: all 0.3s;
      border: none;
      --tw-shadow: 0 4px 6px -1px rgb(196 50 0), 0 2px 4px -2px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
        0 2px 4px -2px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),
        var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
      a {
        color: #fff;
        &:hover {
          color: rgba(30, 21, 72, 0.9215686275);
          // opacity: 0.8;
        }
      }
    }
  }
  .hamburger-menu {
    display: none;
  }
  @media screen and (max-width: 1000px) {
    justify-content: center;
    .logo-main {
      &-img {
        width: 130px;
      }
      margin-right: auto;
    }
    .nav-items {
      &.lg-screen {
        display: none;
      }
      &.sm-screen {
        &.active {
          visibility: visible;
          right: 0;
        }
      }
    }
    .btn-primary {
      padding: 10px 15px;
    }
    .hamburger-menu {
      cursor: pointer;
      div[class^="line"] {
        width: 30px;
        height: 5px;
        background: #eb4d4d;
        border-radius: 5px;
        transition: all 0.3s;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-left: 20px;

      &.clicked {
        .line-1 {
          transform: rotate(45deg) translate(5px, 9.5px);
        }
        .line-3 {
          transform: rotate(-45deg) translate(5px, -9.5px);
        }
        .line-2 {
          transform: translateX(100px);
        }
      }
    }
  }
  @media screen and (max-width: 391px) {
    padding: 5px 20px;
    .btn-primary {
      font-size: 11px !important;
    }
    .nav-items {
      &.sm-screen {
        top: 51px;
      }
    }
  }
}
