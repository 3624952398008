.tools-main {
  background-color: #fff0e0;
  color: #07003a;
  //   padding: 65px 20px 70px 20px;
  padding: 65px 20px 65px 20px;
  //   padding-bottom: 65px;
  &-box {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    .tools-slider {
      margin-top: 30px;
      margin-bottom: 30px;
      &-main {
        max-width: 650px;
        margin: 0 auto;
        .swiper-controller {
          position: relative;
          max-width: 520px;
          //   max-width: 440px;
          margin: 0 auto;
          .swiper {
            max-width: 516px;
            .swiper-wrapper {
              padding: 5px 5px 20px 5px;
              .swiper-slide {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                // width: 135px !important;
                // height: 135px !important;
                width: 160px !important;
                height: 160px !important;
                padding: 10px;
                box-shadow: 0px 3px 2px 0px rgb(34 82 107 / 8%);
                border: 3px solid #ffffff;
                .tool-image-holder {
                  .tool-image {
                    width: 100%;
                  }
                }
                &.swiper-slide-active {
                  position: relative;
                  border: 3px solid #21516c;
                  box-shadow: none;
                  .arrow-down {
                    width: 0;
                    height: 0;
                    border-left: 13px solid transparent;
                    border-right: 13px solid transparent;
                    border-top: 19px solid #21516c;
                    position: absolute;
                    left: 50%;
                    bottom: -19px;
                    transform: translateX(-50%);
                    &::after {
                      content: "";
                      width: 0;
                      height: 0;
                      border-left: 9px solid transparent;
                      border-right: 9px solid transparent;
                      border-top: 15px solid #fff;
                      position: absolute;
                      left: -9px;
                      top: -19px;
                    }
                  }
                }
              }
            }
          }
          .swiper-navigation {
            &-next,
            &-prev {
              position: absolute;
              top: 0;
              z-index: 9;
              transform: translate(-2%, 55%);
              transition: 0.3s all;
            }
            &-next {
              right: -36px;
            }
            &-prev {
              left: -36px;
            }
            &-next,
            &-prev {
              width: 72px;
              height: 72px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #fff;
              border-radius: 50%;
              cursor: pointer;
              box-shadow: 0px 20px 50px -12px rgb(34 82 107 / 40%);

              .next-black,
              .prev-black,
              .next-white,
              .prev-white {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(65%, 65%);
              }
              .next-black,
              .prev-black {
                visibility: hidden;
              }
              &:hover {
                background: #004052;
                .next-white,
                .prev-white {
                  visibility: hidden;
                }
                .next-black,
                .prev-black {
                  visibility: visible;
                }
              }
            }
          }
        }
        .swiper-controlled {
          margin-top: 35px;
          .swiper .swiper-slide {
            opacity: 0.3;
            text-align: justify;
            .inner-main {
              border: 3px solid #ffffff;
              overflow: hidden;
            }
            h2 {
              padding-top: 15px;
              text-align: center;
            }
            p {
              max-height: 260px;
              padding: 15px;
              margin-bottom: 25px;
            }
            &-active {
              opacity: 1;
              .inner-main {
                p {
                  overflow: auto;
                  //   FIREFOX
                  scrollbar-width: thin;
                  scrollbar-color: orange lightyellow;

                  //   Others
                  &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    // background-color: #f5f5f5;
                    background-color: #f90;
                    background-image: -webkit-linear-gradient(
                      45deg,
                      rgba(255, 255, 255, 0.2) 25%,
                      transparent 25%,
                      transparent 50%,
                      rgba(255, 255, 255, 0.2) 50%,
                      rgba(255, 255, 255, 0.2) 75%,
                      transparent 75%,
                      transparent
                    );
                    border-radius: 8px;
                  }

                  &::-webkit-scrollbar {
                    width: 8px;
                    background-color: #f5f5f5;
                  }

                  &::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    background-image: -webkit-gradient(
                      linear,
                      left bottom,
                      left top,
                      color-stop(0.44, rgb(122, 153, 217)),
                      color-stop(0.72, rgb(73, 125, 189)),
                      color-stop(0.86, rgb(28, 58, 148))
                    );
                  }
                }
              }
            }
          }
        }
        @media screen and (max-width: 520px) {
          .swiper-controller {
            max-width: 315px;
            .swiper {
              //   max-width: 245px;
              max-width: 311px;
              .swiper-wrapper {
                .swiper-slide {
                  //   width: 70px !important;
                  //   height: 70px !important;
                  width: 95px !important;
                  height: 95px !important;
                }
              }
            }
            .swiper-navigation {
              &-next {
                right: -17px;
              }
              &-prev {
                left: -17px;
              }
              &-next,
              &-prev {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                border-radius: 50%;
                cursor: pointer;
                box-shadow: 0px 20px 50px -12px rgb(34 82 107 / 40%);
                img {
                  width: 25px !important;
                  height: 25px !important;
                }
                .next-black,
                .prev-black,
                .next-white,
                .prev-white {
                  transform: translate(53%, 50%);
                }
              }
            }
          }
        }
      }
    }
  }
}
