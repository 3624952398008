.contactus-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;

  & > div {
    .contactus-form-field {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      //   gap: 15px;
    }
    input,
    textarea {
      font-size: 20px;
      padding: 15px;
      border: 2px solid #fff;
      margin: 0 15px;
      width: 309px;
      &:focus {
        border: 2px solid #233340;
        // border-radius: 0;
      }
      &.form-error {
        border: 2px solid #be060c;
      }
    }
    textarea {
      height: 200px;
    }
    label {
      font-size: 25px;
      width: 110px;
    }
    sup {
      font-size: 28px;
      cursor: pointer;
    }
    p {
      //   flex: 100%;
      margin-top: 5px;
      margin-left: 140px;
      color: #be060c;
      height: 20px;
    }
  }
  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    button {
      cursor: pointer;
      padding: 15px 30px;
      border: 2px solid #fff;
      font-size: 25px;
      font-weight: 600;

      &:hover {
        background: #fff;
        border: 2px solid #233340;
      }

      svg {
        margin-left: 10px;
      }
    }
    .loading-gif {
      width: 120px;
      height: 64px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    gap: 5px;

    & > div {
      label {
        font-size: 18px;
        width: 75px;
      }
      input,
      textarea {
        width: 200px;
        font-size: 15px;
        margin: 0 10px;
      }
      input {
        height: 45px;
      }
      textarea {
        height: 100px;
      }
      p {
        font-size: 12px;
        margin-left: 100px;
      }
    }
    &-button {
      button {
        padding: 10px 30px;
        font-size: 18px;

        svg {
          width: 18px !important;
          height: 18px !important;
        }
      }
    }
  }
}

#the-count {
  float: right;
  padding: 0.1rem 0 0 0;
  font-size: 0.875rem;
  margin-top: 5px;
  width: 72px;
}
