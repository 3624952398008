.services-section {
  margin-top: 65px !important;
  .services-summary {
    span:not(:last-child) {
      display: block;
      margin-bottom: 25px;
    }
  }
  .services-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    height: 60px;
    button {
      padding: 15px;
      color: rgb(255, 204, 0);
      background: transparent;
      border: 2px solid rgb(255, 204, 0);
      cursor: pointer;
      transition: 0.3s all;
      font-size: 18px;
      &:hover {
        color: #fff;
        background: rgb(255, 204, 0);
        border: 2px solid #fff;
        font-weight: 600;
        font-size: 19px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .services-summary {
      span:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
