.blogs-home-main-box-context {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 60px;
  transition: all 0.1s ease-in-out;
  .blogs-item {
    transition: all 0.1s ease-in;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    margin-bottom: 30px;
    flex-basis: calc(33.3333333333% - 30px);
    background-color: #fff;

    text-decoration: none;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter, -webkit-text-decoration-color,
      -webkit-backdrop-filter;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    &:hover {
      --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
      --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .blogs-link {
      text-decoration: none;
    }
    @media screen and (max-width: 1025px) {
      flex-basis: calc(50% - 30px);
    }
    @media screen and (max-width: 480px) {
      flex-basis: 100%;
    }
  }

  //   @media screen and (max-width: 850px) {
  //     max-width: 420px;
  //     justify-content: center;
  //   }
}
